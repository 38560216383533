// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-booking-js": () => import("./../../../src/pages/booking.js" /* webpackChunkName: "component---src-pages-booking-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-job-vacancy-js": () => import("./../../../src/pages/job-vacancy.js" /* webpackChunkName: "component---src-pages-job-vacancy-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-min-side-js": () => import("./../../../src/pages/min-side.js" /* webpackChunkName: "component---src-pages-min-side-js" */),
  "component---src-pages-mulighet-bilxtra-bilxtra-konto-js": () => import("./../../../src/pages/mulighet-bilxtra/bilxtra-konto.js" /* webpackChunkName: "component---src-pages-mulighet-bilxtra-bilxtra-konto-js" */),
  "component---src-pages-mulighet-bilxtra-delegaranti-js": () => import("./../../../src/pages/mulighet-bilxtra/delegaranti.js" /* webpackChunkName: "component---src-pages-mulighet-bilxtra-delegaranti-js" */),
  "component---src-pages-mulighet-bilxtra-digitalservicehefte-js": () => import("./../../../src/pages/mulighet-bilxtra/Digitalservicehefte.js" /* webpackChunkName: "component---src-pages-mulighet-bilxtra-digitalservicehefte-js" */),
  "component---src-pages-mulighet-bilxtra-fritt-verkstedvalg-js": () => import("./../../../src/pages/mulighet-bilxtra/Fritt-verkstedvalg.js" /* webpackChunkName: "component---src-pages-mulighet-bilxtra-fritt-verkstedvalg-js" */),
  "component---src-pages-mulighet-bilxtra-mobilitetsgaranti-js": () => import("./../../../src/pages/mulighet-bilxtra/mobilitetsgaranti.js" /* webpackChunkName: "component---src-pages-mulighet-bilxtra-mobilitetsgaranti-js" */),
  "component---src-pages-mulighet-bilxtra-veihjelp-js": () => import("./../../../src/pages/mulighet-bilxtra/Veihjelp.js" /* webpackChunkName: "component---src-pages-mulighet-bilxtra-veihjelp-js" */),
  "component---src-pages-personvern-js": () => import("./../../../src/pages/personvern.js" /* webpackChunkName: "component---src-pages-personvern-js" */),
  "component---src-pages-service-og-reparasjon-air-condition-js": () => import("./../../../src/pages/service-og-reparasjon/air-condition.js" /* webpackChunkName: "component---src-pages-service-og-reparasjon-air-condition-js" */),
  "component---src-pages-service-og-reparasjon-clutch-og-brems-js": () => import("./../../../src/pages/service-og-reparasjon/clutch-og-brems.js" /* webpackChunkName: "component---src-pages-service-og-reparasjon-clutch-og-brems-js" */),
  "component---src-pages-service-og-reparasjon-diagnose-og-systemscan-js": () => import("./../../../src/pages/service-og-reparasjon/diagnose-og-systemscan.js" /* webpackChunkName: "component---src-pages-service-og-reparasjon-diagnose-og-systemscan-js" */),
  "component---src-pages-service-og-reparasjon-el-bil-js": () => import("./../../../src/pages/service-og-reparasjon/el-bil.js" /* webpackChunkName: "component---src-pages-service-og-reparasjon-el-bil-js" */),
  "component---src-pages-service-og-reparasjon-eu-kontroll-js": () => import("./../../../src/pages/service-og-reparasjon/EU-kontroll.js" /* webpackChunkName: "component---src-pages-service-og-reparasjon-eu-kontroll-js" */),
  "component---src-pages-service-og-reparasjon-hjulstillingskontroll-js": () => import("./../../../src/pages/service-og-reparasjon/hjulstillingskontroll.js" /* webpackChunkName: "component---src-pages-service-og-reparasjon-hjulstillingskontroll-js" */),
  "component---src-pages-service-og-reparasjon-motor-og-service-js": () => import("./../../../src/pages/service-og-reparasjon/motor-og-service.js" /* webpackChunkName: "component---src-pages-service-og-reparasjon-motor-og-service-js" */),
  "component---src-pages-service-og-reparasjon-oljeskift-js": () => import("./../../../src/pages/service-og-reparasjon/Oljeskift.js" /* webpackChunkName: "component---src-pages-service-og-reparasjon-oljeskift-js" */),
  "component---src-pages-service-og-reparasjon-reparasjon-av-alle-bilmerker-js": () => import("./../../../src/pages/service-og-reparasjon/reparasjon-av-alle-bilmerker.js" /* webpackChunkName: "component---src-pages-service-og-reparasjon-reparasjon-av-alle-bilmerker-js" */),
  "component---src-pages-service-og-reparasjon-reparasjon-av-tilhengere-js": () => import("./../../../src/pages/service-og-reparasjon/reparasjon-av-tilhengere.js" /* webpackChunkName: "component---src-pages-service-og-reparasjon-reparasjon-av-tilhengere-js" */),
  "component---src-templates-collection-page-js": () => import("./../../../src/templates/collectionPage.js" /* webpackChunkName: "component---src-templates-collection-page-js" */),
  "component---src-templates-workshop-js": () => import("./../../../src/templates/workshop.js" /* webpackChunkName: "component---src-templates-workshop-js" */)
}

