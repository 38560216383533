import React, { createContext, useState } from 'react'

export const RegNoContext = createContext({})

export const RegNoProvider = (props) => {
  const [regNo, setRegNo] = useState('')
  const [vehicle, setVehicle] = useState(undefined)

  return (
    <RegNoContext.Provider
      value={{
        regNo,
        setRegNo,
        vehicle,
        setVehicle,
      }}
    >
      {props.children}
    </RegNoContext.Provider>
  )
}
